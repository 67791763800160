import Link from "next/link"
import { Box, Grid, Typography, Container } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { CheckCircleOutline } from "@mui/icons-material"
import { GetStaticProps } from "next"
import { Trans, useTranslation } from "react-i18next"
import * as prismic from "@prismicio/client"
import { ARTICLES_REVALIDATE_TIMER, PRISMIC_DE_URL } from "../sharedConstants"

import LinearGradientBox from "components/LinearGradientBox"
import TitleSection from "components/TitleSection"
import { Layout } from "components/Layout"
import { ContactBlock } from "components/ContactBlock"
import FAQ from "components/FAQ"
import RoundedCard from "components/RoundedCard"
import DrivingLessonMenuPopover from "components/DrivingLessonMenuPopover"
import BuyNowPayLaterBubble from "components/BuyNowPayLaterBubble"
import DriversEdMenuPopover from "components/DriversEdMenuPopover"
import Review from "components/Review"
import IconTextBlock from "components/IconTextBlock"
import ImageTextBlock from "components/ImageTextBlock"
import HowToGetLicenseAccordion from "components/HowToGetLicenseAccordion"
import {
  features,
  safetyFeatures,
  officeHours,
  NUM_STUDENTS,
  NUM_FIVE_STAR_REVIEWS,
} from "components/constants"
import NextImage from "next/image"
import { btwReviews, faqContent } from "components/homeContent"
import TopLocations from "components/TopLocations"
import PaymentLogos from "components/PaymentLogos"
import ArticlePreview from "components/ArticlePreview"
import { makeStyles } from "@mui/styles"
import ImageRandomizer from "components/ImageRandomizer"

const useStyles = makeStyles((theme: any) => ({
  thickUnderline: {
    borderBottom: `4px solid ${theme.palette.secondary.main}`,
    display: "inline-block",
    lineHeight: 0.85,
  },
}))

interface Props {
  articles: prismic.PrismicDocument[]
  category: prismic.PrismicDocument
}

const Index: React.FC<Props> = ({ articles, category }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { t } = useTranslation()
  const classes = useStyles(theme)

  return (
    <Layout>
      <HowToGetLicenseAccordion control={["path", "state"]} />
      <Container maxWidth="lg" sx={{ display: "flex", py: 4 }}>
        <Box sx={{ flex: 2 }}>
          <Box>
            <Typography
              sx={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                color: theme.palette.secondary.dark,
                textTransform: "uppercase",
              }}
            >
              {`Coastline ${t("Driving School")}`}
            </Typography>
          </Box>
          <Typography
            variant="h1"
            sx={{
              fontSize: isMobile ? "2.6rem" : "4.1rem",
              fontWeight: 700,
              lineHeight: isMobile ? "0.95" : "1",
              mb: "20px",
              mt: 0,
            }}
          >
            We teach <span className={classes.thickUnderline}>safe</span>
            {" and "}
            <span className={classes.thickUnderline}>confident</span>
            {" drivers "}
            <span style={{ whiteSpace: "nowrap" }}>
              <span className={classes.thickUnderline}>for life</span>.
            </span>
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ my: 4 }}
            variantMapping={{ h6: "p" }}
          >
            <Trans i18nKey="home1">
              Learn to drive with Coastline and gain the skills and confidence
              you need for life, whether you&apos;re a first-time driver or need
              a refresher before your test.
            </Trans>
          </Typography>
          <RoundedCard
            style={{
              borderRadius: 0,
              borderLeft: `${theme.palette.secondary.main} 4px solid`,
            }}
          >
            <p style={{ marginTop: 0 }}>
              <strong>
                {`${t("Get started by choosing in-car lessons or a course")}:`}
              </strong>
            </p>
            <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
              <Box sx={{ mr: 1 }}>
                <DrivingLessonMenuPopover />
                <BuyNowPayLaterBubble />
              </Box>
              <DriversEdMenuPopover />
            </Box>
          </RoundedCard>
        </Box>
        {!isMobile && (
          <Box sx={{ m: 4 }}>
            <ImageRandomizer />
          </Box>
        )}
      </Container>
      <LinearGradientBox
        title={`Driving Lessons Backed by Over ${NUM_FIVE_STAR_REVIEWS} 5-Star Reviews`}
      >
        <Grid container spacing={3} sx={{ mt: 2 }}>
          {btwReviews.map((review) => (
            <Grid item xs={12} sm={6} md={3} key={review.name}>
              <Review
                text={review.text}
                name={review.name}
                location={review.location}
                rating={review.rating}
              />
            </Grid>
          ))}
        </Grid>
        <Link
          style={{
            display: "block",
            marginTop: 30,
            textAlign: "center",
            color: "#fff",
          }}
          href="/coastline-academy-driving-school-reviews"
        >
          {`${t("Click here to read more Coastline reviews")}.`}
        </Link>
      </LinearGradientBox>
      <TitleSection
        title={`Over ${NUM_STUDENTS} Students Have Chosen Coastline Academy As Their Driving School`}
      >
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          variantMapping={{ h6: "p" }}
        >
          See why 98% of students across the country rate their driving lesson
          with us 5-stars.
        </Typography>
        <Grid container spacing={5} sx={{ mt: 1 }}>
          {features.generic.map((feature) => (
            <Grid item xs={12} md={6} key={feature.head}>
              <IconTextBlock title={feature.head} icon={feature.icon}>
                {feature.text}
              </IconTextBlock>
            </Grid>
          ))}
        </Grid>
      </TitleSection>
      <TitleSection
        style={{ background: theme.palette.backgroundSecondary.main }}
        title={t("Safety Lives At Our Driving School")}
      >
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          variantMapping={{ h6: "p" }}
        >
          We go above and beyond expectations to provide a safe learning
          experience.
        </Typography>
        <Box sx={{ mt: 3 }}>
          <ImageTextBlock
            image={
              <NextImage
                src="https://res.cloudinary.com/coastline-academy/image/upload/vehicle-images/corolla-new-graphics-white-to-right_e7yso1"
                alt="Coastline Corolla"
                width={0}
                height={0}
                priority={false}
                sizes="100vw"
                style={{
                  borderRadius: "10%",
                  width: "100%",
                  height: "auto",
                }}
              />
            }
          >
            <Grid container spacing={3}>
              {safetyFeatures.map((feature) => (
                <Grid item xs={12} key={feature.head}>
                  <IconTextBlock title={feature.head} icon={feature.icon}>
                    {feature.text}
                  </IconTextBlock>
                </Grid>
              ))}
            </Grid>
          </ImageTextBlock>
        </Box>
        <Grid container spacing={8} alignItems="center" sx={{ mt: 4 }}>
          <Grid item md={6} sm={12}>
            <div style={{ maxWidth: 500, margin: "0 auto" }}>
              <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
                {t("The Coastline Guarantee")}
              </Typography>
              <IconTextBlock
                title={`${t("home2")}.`}
                icon={<CheckCircleOutline />}
              >
                <Typography>
                  If for any reason you are unsatisfied with your driving
                  lesson, we will make it right. We can redo your lesson either
                  with the same instructor or a different one, at your option.
                  Or, if you prefer, you can get a full refund.
                </Typography>
                <PaymentLogos />
              </IconTextBlock>
            </div>
            <br />
          </Grid>
          <Grid item md={6} sm={12}>
            <RoundedCard
              style={{
                borderTop: `${theme.palette.secondary.main} 4px solid`,
              }}
            >
              <Link
                href="/articles"
                style={{ color: theme.palette.primary.main }}
              >
                <span style={{ fontSize: 12 }}>&laquo; All Articles</span>
              </Link>
              <h2 style={{ fontWeight: 400 }}>
                {`Latest ${category.data.category} Articles`}
              </h2>
              {articles.map((article) => (
                <ArticlePreview
                  key={article.id}
                  article={article}
                  category={category}
                />
              ))}
            </RoundedCard>
          </Grid>
        </Grid>
      </TitleSection>
      <LinearGradientBox
        title={t(
          "Driving School Serving Thousands of Cities in Eight States Across America",
        )}
      >
        <TopLocations />
      </LinearGradientBox>
      <TitleSection title="How Can we Help?">
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          variantMapping={{ h6: "p" }}
        >
          {`Our customer service team is available ${officeHours}.`}
        </Typography>
        <ContactBlock />
      </TitleSection>
      <TitleSection
        title={t("Frequently Asked Questions")}
        style={{ backgroundColor: "backgroundSecondary.main" }}
      >
        <FAQ content={faqContent} />
      </TitleSection>
    </Layout>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const client = prismic.createClient(PRISMIC_DE_URL)
  const categoryDoc = await client.getByUID("article_category", "news")

  const articles = await client.getAllByType("article", {
    predicates: [prismic.filter.at("my.article.category", categoryDoc.id)],
    orderings: [
      {
        field: "my.article.date",
        direction: "desc",
      },
    ],
    limit: 4,
  })

  return {
    props: {
      articles,
      category: categoryDoc,
    },
    revalidate: ARTICLES_REVALIDATE_TIMER,
  }
}

export default Index
