import { Typography, Box } from "@mui/material"
import Link from "next/link"
import { RichText } from "prismic-reactjs"
import { PrismicDocument } from "@prismicio/client/*"

import { Slice } from "./SliceZones"

export interface ArticleData {
  title: any
  category: PrismicDocument
  description: [{ text: string }]
  feature_image?: any
  date: string
  body: Slice[]
}

interface Props {
  article: PrismicDocument
  category?: PrismicDocument
}

const ArticlePreview: React.FC<Props> = ({ article, category }) => {
  return (
    <Box sx={{ my: 2 }}>
      <Typography>
        <Link href={`/article/${article.uid}`}>
          <span>{RichText.asText(article.data.title)}</span>
        </Link>
      </Typography>
      <Box
        sx={{
          color: "palette.text.secondary",
          fontSize: "12px",
        }}
      >
        Published in{" "}
        {category ? (
          <Link href={`/articles/${category.uid}`}>
            <span>{category.data.category}</span>
          </Link>
        ) : (
          "Unclassified"
        )}
      </Box>
    </Box>
  )
}

export default ArticlePreview
